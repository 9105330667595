"use client";

import { useEffect, useState } from "react";
import axios from "axios";
import ReactLoading from "react-loading";
import { useTranslation } from "next-i18next";
import { Sparklines, SparklinesLine } from "react-sparklines";
import { North, South } from "@mui/icons-material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import PauseIcon from "@mui/icons-material/Pause";
import ReplayIcon from "@mui/icons-material/Replay";

export default function LivePricing() {
  const { t } = useTranslation("common");
  const [cryptoData, setCryptoData] = useState({});

  const [priceHistory, setPriceHistory] = useState({});
  const [loading, setLoading] = useState(true);

  const [eximPrice, setEximPrice] = useState({
    token: "EXIM",
    price: 0.565,
    change: 1.59,
  });

  useEffect(() => {
    const fetchCryptoData = async () => {
      try {
        // Fetch data from the new API
        const response = await axios.get(
          "https://coimex.net/api/v1/blockchain/tokens/prices/USDT"
        );
        console.log("response", response.data.data);
        // Process the new API data

        setCryptoData(response.data.data);

        // Generate price history data
        const historyMap = {};
        Object.entries(combinedData).forEach(([token, data]) => {
          const basePrice = data.usd.buy;
          const change = data.change;

          // Generate sparkline data based on simulated price change
          const sparkline = Array.from({ length: 24 }, (_, i) => {
            const variation =
              (Math.sin(i / 4) * 0.05 + Math.random() * 0.02 - 0.01) *
              basePrice;
            return basePrice + variation;
          });

          historyMap[token] = {
            sparkline,
            change,
          };
        });

        setPriceHistory(historyMap);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching cryptocurrency data:", error);
        setLoading(false);
      }
    };

    // Fetch data immediately and every 60 seconds
    fetchCryptoData();
    const interval = setInterval(fetchCryptoData, 60000);
    return () => clearInterval(interval);
  }, []);

  console.log("cryptoData", cryptoData);

  return (
    <div className="bg-[#D7DAE3] flex flex-col items-center relative ">
      {loading ? (
        <div className="flex items-center justify-center pt-2 pb-2 ">
          <ReactLoading
            type="bars"
            color="#1A73E9"
            height={24}
            className="flex items-center justify-center"
          />
        </div>
      ) : cryptoData ? (
        <div className="w-full text-base font-normal flex items-center justify-center pt-2 pb-2 gap-8 overflow-x-auto whitespace-nowrap ">
          {cryptoData.map((token, index) => (
            <div
              key={index}
              className="text-gray-600 self-center flex items-center font-montserrat"
            >
              <span className=" mr-2 text-[#313033] font-montserrat">
                {token.baseToken}
              </span>
              <span className="text-[#1A73E9] mr-2 font-montserrat">
                ${token.price}
              </span>{" "}
              {token.changePercentage > 0 ? (
                <ArrowDropUpIcon
                  className="text-[#4C9C18] text-[24px]"
                  title="Increase"
                />
              ) : token.changePercentage < 0 ? (
                <ArrowDropDownIcon
                  className="text-red-600 text-[24px]"
                  title="Decrease"
                />
              ) : (
                <PauseIcon
                  className="text-yellow-500 text-[20px]"
                  title="No Change"
                />
              )}
              {token?.changePercentage > 0 ? (
                <span className="text-[#4C9C18] font-montserrat mr-16">
                  {token.changePercentage}%
                </span>
              ) : token?.changePercentage < 0 ? (
                <span className="text-red-600 font-montserrat mr-16">
                  {token.changePercentage}%
                </span>
              ) : (
                <span className="text-[#757575] font-montserrat mr-16">
                  {token?.changePercentage}%
                </span>
              )}
            </div>
          ))}
        </div>
      ) : (
        <p className="text-center text-red-500 mr-2 flex items-center font-montserrat">
          Failed to fetch commodity tokens data
          <ReplayIcon
            className="text-[#1A73E9] cursor-pointer"
            onClick={() => {
              setLoading(true);
              fetchCryptoData();
            }}
          />
        </p>
      )}
    </div>
  );
}
