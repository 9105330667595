import { Divider } from "@mui/material";
import Link from "next/link";
import { useRef, useState } from "react";
import { MenuDrawer } from "../../components/Drawer/CoimexMenu";
import { Menu } from "@headlessui/react";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { Disclosure } from "@headlessui/react";
import React from "react";
import LivePricing from "../../../UI/components/Box/LivePricingData";

export function Header(props) {
  const menuDrawer = useRef();
  const router = useRouter();
  const { t, i18n } = useTranslation();
  const [hoveredItem, setHoveredItem] = useState(null);
  const [clickedItem, setClickedItem] = useState(null);
  const [containerHovered, setContainerHovered] = useState(false);

  const links = [
    { id: "1", href: "/newPricing?package=importer", label: t("Header.9") },
    { id: "2", href: "/newPricing?package=exporter", label: t("Header.10") },
    { id: "4", href: "/newPricing?package=Both", label: t("Header.11") },
  ];
  const linksPlatform = [
    { id: "1", href: "/importer-platform", label: t("Header.8") },
    { id: "2", href: "/exporter-platform", label: t("Header.7") },
  ];

  const blockChain = [
    { id: "1", href: "/escrow-safe-payment", label: t("Header.16") },
    { id: "2", href: "/prompt-pay-assurance", label: t("Header.17") },
    { id: "3", href: "/commodity-token-marketplace", label: t("Header.18") },
    { id: "4", href: "/future-blockchain-solutions", label: t("Header.15") },
    { id: "5", href: "https://eximtoken.co/", label: t("Header.14") },
  ];

  async function handleLang(key) {
    i18n.changeLanguage(key);
    router.push(router.asPath, undefined, { locale: key });
    localStorage.setItem("lang", key);
  }

  const { asPath } = useRouter();

  return (
    <Menu>
      <div className={"bg-PrimaryA"}>
        {asPath !== "/waiting-list" && (
          <div className=" h-[55px] flex justify-between m-auto items-center w-11/12 px-1">
            <Link href={"/"}>
              <div>
                <img
                  loading="lazy"
                  alt="Coimex"
                  width="100%"
                  height="100%"
                  className="cursor-pointer"
                  src="/images/logoSvg.svg"
                />
              </div>
            </Link>
            <Link href={"https://app.coimex.co"}>
              <p className="font-montserrat font-semibold select-none text-[#38466D] cursor-pointer hidden md:flex leading-6  text-base">
                {t("Header.0")}
              </p>
            </Link>
            <MenuSvg onClick={() => menuDrawer.current.openSheet()} />
          </div>
        )}

        {router.route === "/" && <LivePricing />}

        <div
          className={`${
            router.route === "/"
              ? "absolute bg-white/5 backdrop-blur-sm bg-opacity-50 "
              : router.route === "/token-sale"
              ? "absolute bg-white/10 "
              : "bg-PrimaryB"
          }  w-full backdrop-blur-sm`}
        >
          <div className=" hidden md:flex w-11/12 m-auto justify-between items-center ">
            <div
              className={`px-3 flex items-center transition-all ease-out h-[64px] duration-300
              } ${hoveredItem === "blockchain" ? "h-[64px]" : "h-[72px]"} `}
            >
              <Disclosure>
                <Menu>
                  <Menu.Button
                    onMouseEnter={() => setHoveredItem("platform")}
                    onMouseLeave={() => setHoveredItem(null)}
                    onClick={() => setClickedItem("platform")}
                    className={`py-3 py-3 font-montserrat ml-12 text-textColor hidden md:flex leading-6 text-base select-none cursor-pointer transition ease-out transform ${
                      clickedItem === "platform"
                        ? "font-bold scale-110 duration-0" // Instant zoom when clicked
                        : hoveredItem === "platform"
                        ? "font-bold scale-110 duration-300" // Zoom in on hover
                        : hoveredItem !== null
                        ? "font-light scale-100 duration-300"
                        : "font-semibold scale-100 duration-300"
                    }`}
                  >
                    {t("Header.1")}
                  </Menu.Button>
                  <Menu.Items className="h-24 w-64 absolute rounded-md justify-around p-4 flex flex-col self-start mt-12 bg-[#D9D9D91A] z-30 ">
                    {linksPlatform.map((link) => (
                      /* Use the `active` state to conditionally style the active item. */
                      <Menu.Item
                        className="text-textColor text-base font-MontserratRegular hover:font-MontserratSemiBold"
                        key={link.href + link.id}
                      >
                        {({ active }) => (
                          <Link key={link.id} href={link.href}>
                            <a {...props}>
                              <span className="text-textColor  cursor-pointer text-base  font-MontserratRegular hover:font-MontserratSemiBold">
                                {link.label}
                              </span>
                            </a>
                          </Link>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Menu>
              </Disclosure>

              <Disclosure>
                <Menu>
                  <Menu.Button
                    onMouseEnter={() => setHoveredItem("blockchain")}
                    onMouseLeave={() => setHoveredItem(null)}
                    onClick={() => setClickedItem("blockchain")}
                    className={`py-3 font-montserrat ml-12 text-textColor hidden md:flex leading-6 text-base select-none cursor-pointer transition ease-out transform ${
                      clickedItem === "blockchain"
                        ? "font-bold scale-110 duration-0" // Instant zoom when clicked
                        : hoveredItem === "blockchain"
                        ? "font-extrabold scale-110 duration-300" // Zoom in on hover
                        : hoveredItem !== null
                        ? "font-light scale-100 duration-300"
                        : "font-semibold scale-100 duration-300"
                    }`}
                  >
                    {t("Header.12")}
                  </Menu.Button>
                  <Menu.Items className="h-52 w-[290px] absolute ml-[125px] rounded-md justify-around p-4 flex flex-col self-start mt-12 bg-[#8791A7] z-30">
                    {blockChain.map((link) => (
                      /* Use the `active` state to conditionally style the active item. */
                      <Menu.Item
                        className="text-textColor text-base   font-MontserratRegular hover:font-MontserratSemiBold"
                        key={link.href + link.id}
                      >
                        {({ active }) => (
                          <Link href={link.href}>
                            <a {...props} key={link.id}>
                              <span className="text-textColor  cursor-pointer text-base  font-MontserratRegular hover:font-MontserratSemiBold">
                                {link.label}
                              </span>
                            </a>
                          </Link>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Menu>
              </Disclosure>

              <Disclosure>
                <Menu>
                  <Menu.Button
                    onMouseEnter={() => setHoveredItem("pricing")}
                    onMouseLeave={() => setHoveredItem(null)}
                    onClick={() => setClickedItem("pricing")}
                    className={`py-3 font-montserrat ml-12 text-textColor hidden md:flex leading-6 text-base select-none cursor-pointer transition ease-out transform ${
                      clickedItem === "pricing"
                        ? "font-bold scale-110 duration-0" // Instant zoom when clicked
                        : hoveredItem === "pricing"
                        ? "font-bold scale-110 duration-300" // Zoom in on hover
                        : hoveredItem !== null
                        ? "font-light scale-100 duration-300"
                        : "font-semibold scale-100 duration-300"
                    }`}
                  >
                    {t("Header.2")}
                  </Menu.Button>
                  <Menu.Items className="h-32 w-64 ml-[250px] absolute rounded-md justify-around p-4 flex flex-col self-start mt-12 bg-[#8791A7] z-30">
                    {links.map((link) => (
                      /* Use the `active` state to conditionally style the active item. */
                      <Menu.Item key={link.href + link.id}>
                        {({ active }) => (
                          <Link key={link.id} href={link.href}>
                            <a {...props}>
                              <span className="text-textColor text-base  cursor-pointer  font-MontserratRegular hover:font-MontserratSemiBold">
                                {link.label}
                              </span>
                            </a>
                          </Link>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Menu>
              </Disclosure>

              <Link href={"/industries"}>
                <a>
                  <p
                    onMouseEnter={() => setHoveredItem("industries")}
                    onMouseLeave={() => setHoveredItem(null)}
                    onClick={() => setClickedItem("industries")}
                    className={`py-3 font-montserrat ml-12 text-textColor hidden md:flex leading-6 text-base select-none cursor-pointer transition ease-out transform ${
                      clickedItem === "industries"
                        ? "font-bold scale-110 duration-0" // Instant zoom when clicked
                        : hoveredItem === "industries"
                        ? "font-bold scale-110 duration-300" // Zoom in on hover
                        : hoveredItem !== null
                        ? "font-light scale-100 duration-300"
                        : "font-semibold scale-100 duration-300"
                    }`}
                  >
                    {t("Header.3")}
                  </p>
                </a>
              </Link>

              <Link href={"/trust"}>
                <a>
                  <p
                    onMouseEnter={() => setHoveredItem("trust")}
                    onMouseLeave={() => setHoveredItem(null)}
                    onClick={() => setClickedItem("trust")}
                    className={`py-3 font-montserrat ml-12 text-textColor hidden md:flex leading-6 text-base select-none cursor-pointer transition ease-out transform ${
                      clickedItem === "trust"
                        ? "font-bold scale-110 duration-0" // Instant zoom when clicked
                        : hoveredItem === "trust"
                        ? "font-bold scale-110 duration-300" // Zoom in on hover
                        : hoveredItem !== null
                        ? "font-light scale-100 duration-300"
                        : "font-semibold scale-100 duration-300"
                    }`}
                  >
                    {t("Header.4")}
                  </p>
                </a>
              </Link>

              <a
                href={`https://coimex.co/blog${
                  router.locale === "tr" ? "/tr" : ""
                }`}
                rel="noopener noreferrer"
              >
                <p
                  onMouseEnter={() => setHoveredItem("blog")}
                  onMouseLeave={() => setHoveredItem(null)}
                  onClick={() => setClickedItem("blog")}
                  className={`py-3 font-montserrat ml-12 text-textColor hidden md:flex leading-6 text-base select-none cursor-pointer transition ease-out transform ${
                    clickedItem === "blog"
                      ? "font-bold scale-110 duration-0" // Instant zoom when clicked
                      : hoveredItem === "blog"
                      ? "font-bold scale-110 duration-300" // Zoom in on hover
                      : hoveredItem !== null
                      ? "font-light scale-100 duration-300"
                      : "font-semibold scale-100 duration-300"
                  }`}
                >
                  {t("Header.5")}
                </p>
              </a>

              <Link href={"/about-us"}>
                <a>
                  <p
                    onMouseEnter={() => setHoveredItem("about")}
                    onMouseLeave={() => setHoveredItem(null)}
                    onClick={() => setClickedItem("about")}
                    className={`py-3 font-montserrat ml-12 text-textColor hidden md:flex leading-6 text-base select-none cursor-pointer transition ease-out transform ${
                      clickedItem === "about"
                        ? "font-bold scale-110 duration-0" // Instant zoom when clicked
                        : hoveredItem === "about"
                        ? "font-bold scale-110 duration-300" // Zoom in on hover
                        : hoveredItem !== null
                        ? "font-light scale-100 duration-300"
                        : "font-semibold scale-100 duration-300"
                    }`}
                  >
                    {t("Header.6")}
                  </p>
                </a>
              </Link>
            </div>
            <div className="flex items-center">
              <p
                onClick={() => handleLang("en")}
                className={`${
                  i18n.language == "en"
                    ? "font-montserrat font-semibold"
                    : "font-montserrat"
                }   text-textColor hidden md:flex leading-6  text-lg select-none cursor-pointer pl-1`}
              >
                English
              </p>
              <Divider
                orientation="vertical"
                variant="middle"
                className="w-[2px] m-3 h-6 bg-textColor"
              />
              <p
                onClick={() => handleLang("tr")}
                className={`${
                  i18n.language == "tr"
                    ? "font-montserrat font-semibold"
                    : "font-montserrat"
                }   text-textColor hidden md:flex leading-6  text-lg select-none cursor-pointer pl-1`}
              >
                Türkçe
              </p>
            </div>
          </div>

          <MenuDrawer
            blockChain={blockChain}
            linksPlatform={linksPlatform}
            links={links}
            ref={menuDrawer}
          />
        </div>
      </div>
    </Menu>
  );
}

const MenuSvg = ({ onClick }) => (
  <svg
    onClick={onClick}
    className="cursor-pointer z-10 md:hidden"
    xmlns="http://www.w3.org/2000/svg"
    height="48"
    width="35"
  >
    <path d="M6 36v-3h36v3Zm0-10.5v-3h36v3ZM6 15v-3h36v3Z" />
  </svg>
);
